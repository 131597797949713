<template>
    <div>
        <a-spin :spinning="loading">
            <a-table
                :columns="colums"
                :dataSource="data"
                bordered
                :rowKey='record=>record.id'
                :pagination="false">
                <template slot="title">
                    <a-radio-group :value="type" @change="handleChange">
                        <a-radio-button :value="0">
                            全部
                        </a-radio-button>
                        <a-radio-button :value="1">
                            新房
                        </a-radio-button>
                        <a-radio-button :value="2">
                            二手房
                        </a-radio-button>
                        <a-radio-button :value="3">
                            租房
                        </a-radio-button>
                        <a-radio-button :value="4">
                            商铺
                        </a-radio-button>
                        <a-radio-button :value="5">
                            写字楼
                        </a-radio-button>
                    </a-radio-group>
                    <a-dropdown style="margin-left: 10px">
                        <a-menu slot="overlay" @click="handleMenuStatusClick($event)">
                        <a-menu-item :key="-1">全部</a-menu-item>
                        <a-menu-item :key="0">上架</a-menu-item>
                        <a-menu-item :key="1">下架</a-menu-item>
                        </a-menu>
                        <a-button v-if="params.status === -1">
                        全部<a-icon type="down" />
                        </a-button>
                        <a-button v-if="params.status === 0">
                        上架<a-icon type="down" />
                        </a-button>
                        <a-button v-if="params.status === 1" >
                        下架
                        <a-icon type="down" />
                        </a-button>
                    </a-dropdown>
                    <a-input-search style="width:250px;margin-left:20px;" v-model="params.keywords" placeholder="输入房产名称搜索" enter-button="搜索" @search="page()"/>
                    <a-button icon="plus" style="margin-left: 10px;" @click="$refs.saveHouse.showModal(null)">添加房产</a-button>
                </template>
                <template slot="image" slot-scope="text">
                    <img :src="text" style="width:100px;height:50px;border-radius:5px;"/>
                </template>
                <template slot="type" slot-scope="text">
                    <a-tag v-if="text === 1" color="green">
                        新房
                    </a-tag>
                    <a-tag v-if="text === 2" color="cyan">
                        二手房
                    </a-tag>
                    <a-tag v-if="text === 3" color="blue">
                        租房
                    </a-tag>
                    <a-tag v-if="text === 4" color="purple">
                        商铺
                    </a-tag>
                    <a-tag v-if="text === 5" color="purple">
                        写字楼
                    </a-tag>
                </template>
                <template slot="status" slot-scope="text, record">
                    <a-dropdown style="margin-right: 10px">
                        <a-menu slot="overlay" @click="handleMenuTypeClick($event, record)">
                        <a-menu-item :key="0">上架</a-menu-item>
                        <a-menu-item :key="1">下架</a-menu-item>
                        </a-menu>
                        <a-button size="small" v-if="text === 0" class="status-one">
                        上架<a-icon type="down" />
                        </a-button>
                        <a-button size="small" v-if="text === 1" class="status-two">
                        下架
                        <a-icon type="down" />
                        </a-button>
                    </a-dropdown>
                </template>
                <template slot="money" slot-scope="text">
                    ￥{{ text }}
                </template>
                <template slot="area" slot-scope="text">
                    {{ text }}㎡
                </template>
                <template slot="action" slot-scope="text, record">
                    <a-button size="small" type="primary" icon="edit" class="action" @click="$refs.saveHouse.showModal(record)">修改</a-button>
                    <a-button size="small" type="primary" icon="edit" class="action" @click="$refs.contactModal.showModal(record.id, 1)">跟进情况</a-button>
                    <a-popconfirm
                        title="确认删除吗？"
                        ok-text="确认"
                        cancel-text="取消"
                        @confirm="del(record.id)"
                        >
                        <a-button icon="delete" style="margin-top:10px;" size="small" type="danger" class="action">删除</a-button>
                    </a-popconfirm>
                </template>
            </a-table>
            <a-pagination
                v-if="data.length > 0"
                showQuickJumper
                style="margin-top:10px;"
                :defaultCurrent="1"
                :total="total"
                :pageSize="params.pageSize"
                @change="pageChange"
            />
        </a-spin>
        <SaveHouse ref="saveHouse" @ok="page"/>
        <ContactModal ref="contactModal"/>
    </div>
</template>
<script>
import SaveHouse from './modules/SaveHouse'
import ContactModal from '@/components/ContactModal'
const colums = [
    { title: '房产封面', align: 'left', width: '5%', dataIndex: 'image', scopedSlots: { customRender: 'image' }},
    { title: '状态', align: 'center', width: '5%', dataIndex: 'status', scopedSlots: { customRender: 'status' }},
    { title: '房产名称', align: 'left', width: '15%', dataIndex: 'name'},
    // { title: '业主姓名', align: 'left', width: '5%', dataIndex: 'owner'},
    // { title: '业主电话', align: 'left', width: '8%', dataIndex: 'ownerMobile'},
    { title: '房产类型', align: 'left', width: '5%', dataIndex: 'type', scopedSlots: { customRender: 'type' }},
    { title: '房产面积', align: 'left', width: '5%', dataIndex: 'area', scopedSlots: { customRender: 'area' }},
    { title: '房产金额', align: 'left', width: '8%', dataIndex: 'money', scopedSlots: { customRender: 'money' }},
    { title: '详细地址', align: 'left', width: '10%', dataIndex: 'address', scopedSlots: { customRender: 'address' }},
    { title: '操作', align: 'left', width: '15%', scopedSlots: { customRender: 'action' } }
]
export default {
    components: {
        SaveHouse,
        ContactModal
    },
    data () {
        return {
            loading: false,
            data: [],
            defaultLogo: require('@/assets/default_logo.png'),
            colums,
            type: 0,
            total: 0,
            params: {
                pageNum: 1,
                pageSize: 10,
                keywords: null,
                status: -1
            }
        }
    },
    mounted () {
        this.page()
    },
    methods: {
        page () {
            let keywords = -1
            if (this.params.keywords !== '' && this.params.keywords !== null ) {
                keywords = this.params.keywords
            }
            this.loading = true
            this.$api.house.page(this.params.pageNum, this.params.pageSize, keywords, this.type, this.params.status).then(res => {
                if (res.status === 0) {
                    this.data = res.data.list
                    this.total = res.data.total
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(err => {
                this.$message.error(err)
            }).finally(_ => {
                this.loading = false
            })
        },
        handleChange (e) {
            this.type = e.target.value;
            this.page()
        },
        del (id) {
            this.$api.house.del(id).then(res => {
                if (res.status === 0) {
                    this.$message.success('成功')
                    this.page()
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(err => {
                this.$message.error(err)
            }).finally(_ => {
                this.loading = false
            })
        },
        pageChange (pageNum) {
            this.params.pageNum = pageNum
            this.page()
        },
        handleMenuTypeClick (e, record) {
            this.$api.house.updateStatus(record.id, e.key).then(res => {
                if (res.status === 0) {
                    this.$message.success('成功')
                    this.page()
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(err => {
                this.$message.error(err)
            }).finally(_ => {
                this.loading = false
            })
            console.log(e)
            console.log(record)
        },
        handleMenuStatusClick (e) {
            this.params.status = e.key
            this.params.pageNum = 1
            this.page()
        }
    }
}
</script>
<style scoped>
    .action {
        margin-right: 10px;
    }
    .status-one{
  background: #87d068; border: 1px solid #87d068; color: white
}
.status-two{
  background: #ccc; border: 1px solid #ccc; color: white
}
</style>

